import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import axios from "axios";

// import { API_PATHNAME, churchName } from "../utils/API";

function AlbumSlider() {
  const [albums, setAlbums] = useState([]);

  const getAlbum = async () => {
    // const APIURL = `${API_PATHNAME}/${churchName}/life/1`;
    // axios.get(APIURL).then((response) => {
    //   setAlbums(response.data);
    // });
    const albums = [
        {
            id: 405,
            title: "성경은 무엇을 말하는가?",
            thumbnail:
            "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043135698.jpg",
            link:
            "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223062347421&categoryNo=11&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:20.000Z",
            updatedAt: "2024-09-04T04:32:46.000Z",
        },
        {
            id: 403,
            title: "대만에서 온 선교편지 <이상수선교사>",
            thumbnail:
            "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/verygoodchurch_default.jpeg",
            link:
            "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223061986477&categoryNo=10&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:18.000Z",
            updatedAt: "2024-09-04T04:32:45.000Z",
        },
        {
            id: 400,
            title: "함께전도",
            thumbnail:
            "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/verygoodchurch_default.jpeg",
            link:
            "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223043958752&categoryNo=11&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:15.000Z",
            updatedAt: "2024-09-04T04:32:44.000Z",
        },
        {
            id: 396,
            title: "임철민목사님",
            thumbnail: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043042319.jpg",
            link: "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223043892805&categoryNo=7&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:10.000Z",
            updatedAt: "2024-09-04T04:32:43.000Z"
        },
        {
            id: 395,
            title: "아동후원",
            thumbnail: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043155213.jpg",
            link: "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223041710330&categoryNo=12&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:09.000Z",
            updatedAt: "2024-09-04T04:32:41.000Z"
        },
        {
            id: 393,
            title: "[공지] 아주대근처 참조은교회(VERY GOOD CHURCH)를 소개합니다!",
            thumbnail: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043031672.jpg",
            link: "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223041688731&categoryNo=6&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:08.000Z",
            updatedAt: "2024-09-04T04:32:40.000Z"
        },
    ];
    setAlbums(albums);
  };

  useEffect(() => {
    getAlbum();
  }, []);

  return (
    <div>
      {albums !== undefined && albums !== null && albums.length > 0 && (
        <SliderDiv>
          {albums.map((item) => {
            return (
              <CardDiv onClick={()=>{
                window.open(item.link);
              }}>
                <ThumbImg src={item.thumbnail} alt="ThumbImg" />
                <Title>{item.title}</Title>
              </CardDiv>
            );
          })}
        </SliderDiv>
      )}
    </div>
  );
}

const SliderDiv = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
`;
const CardDiv = styled.div`
  flex: 0 0 30%;
  scroll-snap-align: start;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ThumbImg = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 4px;
  object-fit: cover;
`;

const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
`;

export default AlbumSlider;
