// import styledEngine from "@mui/styled-engine";
import React from "react";
import { useState, useEffect } from "react";

import styled from "styled-components";
import AppBar from "../components/Appbar";
import KakaoMap from "../components/KakaoMap";
import WrapBox from "../styled_components/WrapBox";
import Footer from "../components/FooterDiv";
import TitleDiv from "../styled_components/TitleDiv";
import LineDiv from "../styled_components/LineDiv";
// import axios from "axios";

// import { API_PATHNAME, churchName } from "../utils/API";

const Directions = () => {
  const [directions, setDirections] = useState();
  const [busInfoImg, setBusInfoImg] = useState();
  const [churchKorName, setChurchKorName] = useState("");

  const getBusInfoImg = async () => {
    // const APIURL = `${API_PATHNAME}/${churchName}/businfo`;
    // axios.get(APIURL).then((response) => {
    //   const img = response.data?.img;
    //   setBusInfoImg(img);
    // });
    const img = "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/BusStationInfo.png";
    setBusInfoImg(img);
  };

  const getDirections = async () => {
    // const APIURL = `${API_PATHNAME}/${churchName}/directions`;
    // axios.get(APIURL).then((response) => {
    //   setDirections(response.data);
    // });
    const directions = {
      lat: 37.27680826575521,
      lng: 127.0412322006258,
    };
    setDirections(directions);
  };

  const getChurchKorName = () => {
    if (process.env.REACT_APP_SITE_TITLE === undefined) return
    setChurchKorName(process.env.REACT_APP_SITE_TITLE)
  }

  useEffect(() => {
    getChurchKorName();
    getBusInfoImg();
    getDirections();
  }, []);

  return (
    <>
      {<AppBar />}
      <WrapBox>
        <TitleDiv>
          <h1>찾아오시는 길</h1>
        </TitleDiv>
        <LineDiv />
        <MapDiv>
          {
            directions !== null && directions !== undefined &&
            churchKorName !== null && churchKorName !== undefined &&
            <KakaoMap lat={directions?.lat} lng={directions?.lng} churchName={churchKorName}/>
          }
        </MapDiv>
        {
          busInfoImg !== undefined && busInfoImg !== null &&
          <BusInfoDiv>
            <BusStationImg src={busInfoImg} alt="BusStationInfo"/>
          </BusInfoDiv>
        }
      </WrapBox>
      <Footer />
    </>
  );
};

const MapDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2%;
`;

const BusInfoDiv = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 1000px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;
`;

const BusStationImg = styled.img`
  height: 100%;
  width: auto;
`;

export default Directions;
