import styled from "styled-components";
import { useState, useEffect } from "react";
import AppBar from "../components/Appbar";
import WrapBox from "../styled_components/WrapBox";
import Footer from "../components/FooterDiv";
import TitleDiv from "../styled_components/TitleDiv";
import LineDiv from "../styled_components/LineDiv";
// import axios from "axios";

// import { API_PATHNAME, churchName } from "../utils/API";

const Welcome = () => {
  const [introduce, setIntroduce] = useState([]);

  const getIntroduce = async () => {
    // const APIURL = `${API_PATHNAME}/${churchName}/welcome`;
    // axios.get(APIURL).then((response) => {
    //   setIntroduce(response.data);
    // });
    const introduce = [
        {
            title: null,
            img: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/WelcomeBanner.png",
            title_order: 1,
        },
        {
            title: "담임목사님 소개",
            img: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/Introduce2.png",
            title_order: 2,
        },
        {
            title: "참조은교회의 사명",
            img: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/Introduce3.png",
            title_order: 3,
        },
        {
            title: "슬로건",
            img: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/Introduce4.png",
            title_order: 4,
        },
    ];
    setIntroduce(introduce);
  };

  useEffect(() => {
    getIntroduce();
  }, []);

  return (
    <>
      {<AppBar />}
      <WrapBox>
        <TitleDiv>
          <h1>환영합니다</h1>
        </TitleDiv>
        <LineDiv />
        <ContentsBoxDiv>
          <>    

              {/* {title !== null &&( */}
              
                {introduce.map((props) => {
              return (
                <>
                  {
                    props.title !== null &&
                    <SubTitleDiv>{props.title}</SubTitleDiv>
                  }
                  <SubListDiv>
                    <IntroduceImg src={props.img} alt="Introduce" />
                  </SubListDiv>
                </>  
                  );
                  })}

          </>
        </ContentsBoxDiv>
      </WrapBox>
      <Footer />
    </>
  );
};

const SubListDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5%;
`;
const SubTitleDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  font-weight: bolder;
  color: #3c4554;
  margin-right: 2.5%;
  margin-bottom: 5%;
  margin: 3%;
`;


const IntroduceImg = styled.img`
  display: flex;
  width: 100%;
  margin-bottom: 5%;
`;

const ContentsBoxDiv = styled.div``;
export default Welcome;
