import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import axios from "axios";
// import { API_PATHNAME, churchName } from "../utils/API";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AssistantDirectionRoundedIcon from '@mui/icons-material/AssistantDirectionRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';

const appBarContent = {
  Info: "환영합니다",
  Time: "예배시간",
  Path: "찾아오시는길",
  label: "교회 생활",
};
const moveToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const AppBar = () => {
  const navigate = useNavigate();

  const [mainLogoURL, setMainLogoURL] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerDiv>
        <DrawerLogoDiv>
          <LogoImg src={mainLogoURL} alt="mainLogo" />
        </DrawerLogoDiv>
        <Divider />
        <DrawerListDiv>
          <DrawerContentDiv onClick={()=>{
            navigate("/welcome");
            moveToTop();
          }}>
            <AccessibilityNewRoundedIcon />
            <h4>환영합니다</h4>
          </DrawerContentDiv>
          <DrawerContentDiv onClick={()=>{
            navigate("/time");
            moveToTop();
          }}>
            <AccessTimeRoundedIcon />
            <h4>예배시간</h4>
          </DrawerContentDiv>
          <DrawerContentDiv onClick={()=>{
            navigate("/directions");
            moveToTop();
          }}>
            <AssistantDirectionRoundedIcon />
            <h4>찾아오시는 길</h4>
          </DrawerContentDiv>
          <DrawerContentDiv onClick={()=>{
            navigate("/churchLife");
            moveToTop();
          }}>
            <CollectionsRoundedIcon />
            <h4>교회 생활</h4>
          </DrawerContentDiv>
        </DrawerListDiv>
      </DrawerDiv>
    </Box>
  );

  const getMainLogo = async () => {
    // const APIURL = `${API_PATHNAME}/${churchName}/logo`;
    // axios
    //   .get(APIURL)
    //   .then(function (response) {
    //     const logoURL = response.data?.logo_img;
    //     setMainLogoURL(logoURL);
    //   })
    const logoURL = "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/+charm_icon.png";
    setMainLogoURL(logoURL);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }
  useEffect(() => {
    getMainLogo()
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
    <AppBarDiv>
      {mainLogoURL !== undefined && mainLogoURL !== null && (
        <>
          <LogoImgDiv
            onClick={() => {
              navigate("/");
              moveToTop();
            }}
          >
            <LogoImg src={mainLogoURL} alt="mainLogo" />
          </LogoImgDiv>
          {
            windowWidth > 680 ?
            <>
              <MenuListDiv>
              <MenuTab
                onClick={() => {
                  navigate("/welcome");
                  moveToTop();
                }}
              >
                {" "}
                {appBarContent.Info}{" "}
              </MenuTab>
              <MenuTab
                onClick={() => {
                  navigate("/time");
                  moveToTop();
                }}
              >
                {appBarContent.Time}{" "}
              </MenuTab>
              <MenuTab
                onClick={() => {
                  navigate("/directions");
                  moveToTop();
                }}
              >
                {" "}
                {appBarContent.Path}{" "}
              </MenuTab>
              <MenuTab
                onClick={() => {
                  navigate("/churchLife");
                  moveToTop();
                }}
              >
                {" "}
                {appBarContent.label}{" "}
              </MenuTab>
            </MenuListDiv>
            </>
            :
            <MenuIconDiv onClick={toggleDrawer("right", true)}>
              <MenuRoundedIcon fontSize="inherit"/>
            </MenuIconDiv>
          }
        </>
      )}
    </AppBarDiv>
    {
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    }
    </>
  );
};

const AppBarDiv = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  z-index: 10;
  width: calc(100% - 30px);
  padding-right: 15px;
  padding-left: 15px;
  max-height: 95px;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: black;
  border-bottom: 1.5mm ridge rgba(211, 220, 50, 0.6);
  background-color: white;
  @media screen and (max-width: 480px) {
   height: 70px;
  }
`;

const LogoImgDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  @media screen and (max-width: 480px) {
    padding: 0px;
  }
  
`;
const LogoImg = styled.img`
  width: 150px;
  height: 60px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    width: 100px;
    height: 40px;
  }
`;

const MenuListDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 30px;
  margin-top: 40px;
  font-size: 20px;

  @media screen and (max-width: 480px) {
    font-size: 13px;
  }

  /* 가로로 배치 */
  flex-direction: row;
`;

const MenuTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  text-align: center; // 추가된 부분
  @media screen and (max-width: 480px) {
    width: 52px;
    padding-left: 2px;
  }
  @media screen and (max-width: 360px) {
    width: 52px;
    padding-left: -12px;
  }

`;

const MenuIconDiv = styled.div`
  font-size: 35px;
  display: flex;
  align-items: center;
`;

const DrawerDiv = styled.div`
`;

const DrawerLogoDiv = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
`;

const DrawerListDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const DrawerContentDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
`;


export default AppBar;
