import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import AppBar from "../components/Appbar";
import WrapBox from "../styled_components/WrapBox";
import Footer from "../components/FooterDiv";
import TitleDiv from "../styled_components/TitleDiv";
import LineDiv from "../styled_components/LineDiv";
// import axios from "axios";

// import { API_PATHNAME, churchName } from "../utils/API";

function ChurchLife() {
  const [albums, setAlbums] = useState([]);
  const [page, setPage] = useState(1);
  const [maxNumPage, setMaxNumPage] = useState(1);
  const [pageRange, setPageRange] = useState({ start: 1, end: 5 });

  const getMaxPage = async () => {
    // const APIURL = `${API_PATHNAME}/${churchName}/life/maxpage`;
    // axios.get(APIURL).then(function (response) {
    //   const MaxPageURL = response.data;
    // });
    setMaxNumPage(1);
};

  const getAlbums = async (page) => {
    // const APIURL = `${API_PATHNAME}/${churchName}/life/${page}`;
    // axios.get(APIURL).then(function (response) {
    //   const BlogAPIURL = response.data;
    //   setAlbums(BlogAPIURL);
    // });
    const albums = [
        {
            id: 405,
            title: "성경은 무엇을 말하는가?",
            thumbnail:
            "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043135698.jpg",
            link:
            "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223062347421&categoryNo=11&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:20.000Z",
            updatedAt: "2024-09-04T04:32:46.000Z",
        },
        {
            id: 403,
            title: "대만에서 온 선교편지 <이상수선교사>",
            thumbnail:
            "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/verygoodchurch_default.jpeg",
            link:
            "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223061986477&categoryNo=10&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:18.000Z",
            updatedAt: "2024-09-04T04:32:45.000Z",
        },
        {
            id: 400,
            title: "함께전도",
            thumbnail:
            "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/verygoodchurch_default.jpeg",
            link:
            "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223043958752&categoryNo=11&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:15.000Z",
            updatedAt: "2024-09-04T04:32:44.000Z",
        },
        {
            id: 396,
            title: "임철민목사님",
            thumbnail: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043042319.jpg",
            link: "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223043892805&categoryNo=7&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:10.000Z",
            updatedAt: "2024-09-04T04:32:43.000Z"
        },
        {
            id: 395,
            title: "아동후원",
            thumbnail: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043155213.jpg",
            link: "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223041710330&categoryNo=12&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:09.000Z",
            updatedAt: "2024-09-04T04:32:41.000Z"
        },
        {
            id: 393,
            title: "[공지] 아주대근처 참조은교회(VERY GOOD CHURCH)를 소개합니다!",
            thumbnail: "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/blog/image_20240904T043031672.jpg",
            link: "https://blog.naver.com/PostView.naver?blogId=goodchurch9006&logNo=223041688731&categoryNo=6&parentCategoryNo=0&viewDate=&currentPage=1&postListTopCurrentPage=&from=postList",
            church_name: "charmjoeun",
            createdAt: "2023-04-26T12:15:08.000Z",
            updatedAt: "2024-09-04T04:32:40.000Z"
        },
    ];
    setAlbums(albums);
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updatePageRange = useCallback(
    (page) => {
      const start = Math.max(1, page - 2);
      const end = Math.min(maxNumPage, start + 4);
      setPageRange({ start, end });
    },
    [maxNumPage]
  );

  useEffect(() => {
    if (page >= 1) {
      getAlbums(page);
      moveToTop();
    }
  }, [page]);

  useEffect(() => {
    getMaxPage();
  }, []);

  useEffect(() => {
    updatePageRange(page);
  }, [page, maxNumPage, updatePageRange]);

  return (
    <>
      <WrapBox>
        <AppBar />
        <TitleDiv>
          <h1>교회 생활</h1>
        </TitleDiv>
        <LineDiv />
        <BlogListDiv>
          {albums.length > 0 && (
            <CardListDiv>
              {albums.map((album) => (
                <CardDiv key={album.id} onClick={() => window.open(album.link)}>
                  <ThumbImg src={album.thumbnail} alt="thumbnail" />
                  <Title>{album.title}</Title>
                </CardDiv>
              ))}
            </CardListDiv>
          )}
        </BlogListDiv>
        <Nav>
          <Button
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            이전
          </Button>
          {Array.from({ length: pageRange.end - pageRange.start + 1 }, (_, i) => (
            <Button
              key={pageRange.start + i}
              onClick={() => setPage(pageRange.start + i)}
              active={page === pageRange.start + i}
            >
              {pageRange.start + i}
            </Button>
          ))}
          <Button
            onClick={() => setPage(page + 1)}
            disabled={page === maxNumPage}
          >
            다음
          </Button>
        </Nav>
      </WrapBox>
      <Footer />
    </>
  );
}

const BlogListDiv = styled.div``;

const CardListDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CardDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(100% / 4 - 40px);
  min-width: 300px;

  @media (max-width: 1024px) {
    width: calc(100% / 3 - 40px);
  }

  @media (max-width: 768px) {
    width: calc(100% / 2 - 40px);
  }

  @media (max-width: 480px) {
    width: calc(100% - 30px);
    padding: 10px;
    margin: 15px;
  }
`;

const ThumbImg = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 4px;
  object-fit: cover;
`;

const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  
  @media (max-width: 500px) {
    gap: 1px;
  }
`;

const Button = styled.button`
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: ${(props) => (props.active ? "blue" : "transparent")};
  color: ${(props) => (props.active ? "white" : "black")};
  border: 1px solid black;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: green;
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
    margin: 0 0.2rem;
  }
`;

export default ChurchLife;
