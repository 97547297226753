import styled from "styled-components";
import { useState, useEffect } from "react";
// import axios from "axios";
// import { API_PATHNAME, churchName } from "../utils/API";

function Footer() {
  const [footerInfo, setFooterInfo] = useState();

  const getAlbum = async () => {
    // const APIURL = `${API_PATHNAME}/${churchName}/info`;
    // axios.get(APIURL).then((response) => {
    //   setFooterInfo(response.data);
    // });
    const footerInfo = {
        "church_name": "charmjoeun",
        "address": "경기도 수원시 팔달구 중부대로223번길 20-7 (우만동) 참조은교회",
        "phone_number": "070-5033-9006",
        "fax_number": "",
        "footer_logo": "https://aiderbucket.s3.ap-northeast-2.amazonaws.com/Footerlogo.png"
    };
    setFooterInfo(footerInfo);
  };

  useEffect(() => {
    getAlbum();
  }, []);

  return (
    <>
    {
      footerInfo !== null && footerInfo !== undefined &&
      <FooterDiv>
        <FooterContainer>
          <FooterLogoDiv>
            <FooterImg src={footerInfo.footer_logo} alt="FooterLogo" />
          </FooterLogoDiv>
          <FooterContent>
            <Address>
              {footerInfo.address}
            </Address>
            <Tel>Tel. {footerInfo.phone_number}</Tel>
            <Provided onClick={()=>{
              window.open("https://aiderintro.notion.site/Aider-91fd5fb36b0944e1a67669e3c2ca5071");
            }}>Provide by Aider</Provided>
          </FooterContent>
        </FooterContainer>
      </FooterDiv>
    }
    </>
  );
}

const FooterDiv = styled.div`
  display: flex;
  justify-content: center;
  background: #3c4554;
  font-weight: bolder;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-family: SpoqaHanSans;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.6;
  color: #ffffff;
  text-align: left;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  position: absolute;
  width: 100%;
  @media screen and (max-width: 480px) { // 모바일 장치에 대한 스타일 조정
    font-size: 12px;
    padding-top: 10px;
  }
`;

const FooterImg = styled.img`
  width: 150px;
  height: 60px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    width: 100px;
    height: 40px;
  }
`;
const FooterLogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 15%;
  padding-left: 42%;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin: 2%;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  place-self: center;
  width: 100%;
`;
const Address = styled.div`
  width: 100%;
  padding-top: 20px;
  @media screen and (max-width: 480px) { // 모바일 장치에 대한 스타일 조정
    font-size: 11.5px;

  }
`;
const Tel = styled.div`
  width: 100%;
`;
const Provided = styled.div`
  padding-top: 10px;
  width: 100%;
  border-bottom: 1px solid #ffffff;
  width: fit-content;
  :hover {
    cursor: pointer;
    color: #ebebeb;
  }
`;
export default Footer;
